@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/datadesign/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/datadesign/src/scss/utility/_mixin.scss";


#page404 {
	.taC {
    display: block;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-align: center;
    margin: 20px 0 0 0;
    @include max-screen(767px) {
      font-size: 15px;
      line-height: 25px;
    }
		a {
      font-size: 16px;
      text-decoration: none;
      color: $color01;
      @include max-screen(767px) {
        @include font-size(16);
      }
    }
	}
	.page-title {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 5px;
    padding-bottom: 50px;
    font-family: $roboto;
  }
}
.page_404 {
 padding: 200px 0 200px;
 @include max-screen(767px) {
  padding: 80px 20px;
 }
}